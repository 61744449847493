import { Box, Center, Flex, Heading, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


function Home(props) {
  const navigate = useNavigate();

  return (
    <Box>
      <Center mt="10%" >
        <Flex direction="row" wrap="wrap">
          <Box pb='2' _hover={{ bg: "blue.300", cursor: "pointer" }} borderWidth={1} onClick={() => { navigate('/categories');} } borderRadius={15} marginEnd={20} bg="blue.100" width={200} >
              <Center p='2'>
                <Image src="/category.png" alt="image" />
              </Center>
              <Center mt='2'>
              <Heading  size='md'>
                  Categories
              </Heading>
              </Center>
          </Box>
          <Box _hover={{ bg: "blue.300", cursor: "pointer" }} borderWidth={1} onClick={() => { navigate('/orders');} } borderRadius={15} marginEnd={20} bg="blue.100" width={200}>
            <Center p='2'>
                <Image src="/order.png" alt="image" />
            </Center>
            <Center mt='2'>
              <Heading size='md'>
                  Orders
              </Heading>
            </Center>
          </Box>
          <Box _hover={{ bg: "blue.300", cursor: "pointer" }} borderWidth={1} onClick={() => { navigate('/Users');} } borderRadius={15} marginEnd={20} bg="blue.100" width={200}>
            <Center p='2'>
                <Image src="/users.png" alt="image" />
            </Center>
            <Center mt='2'> 
              <Heading size='md'>
                  Users
              </Heading>
            </Center>
          </Box>
          <Box _hover={{ bg: "blue.300", cursor: "pointer" }} borderWidth={1} onClick={() => { navigate('/search');} } borderRadius={15} marginEnd={20} bg="blue.100" width={200}>
          <Center p='2'>
                <Image src="/products.png" alt="image" />
            </Center>
          <Center>
              <Heading mt="2" size='md'>
                  Products
              </Heading>
              </Center>
          </Box>  
        </Flex>
      </Center>
    </Box>
  );
}

export default Home;
