import {
  Heading,
  Flex,
  Card,
  CardBody,
  Stack,
  Text,
  useToast,
  Box,
  Badge,
  Center
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import httpService from "../../services/httpService";
import EditUserForm from "../Forms/EditUserForm";
import CommonButtonGroup from "../CommonButtonGroup";
import ModalDialog from "../Forms/ModalDialog";
import { useNavigate } from "react-router-dom";

function ShowAllUsers(props) {
  const [users, setUsers] = useState([]);
  const [lastSelectedUserData, setlastSelectedUserData] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [operation, setOperation] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }
  function handleError(msg) {
    if (msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    } else {
      showToast(msg, "error");
    }
  }
  useEffect(() => {
    httpService.get("users/admin/getAllUsers", setUsers).catch((msg) => {
      handleError(msg);
    });
  }, []);

  function closePopup(json) {
    setIsClose(true);
    setIsOpen(false);
  }

  function handleCardSelection(e, user, index) {
    if (e.currentTarget) {
      if (e.currentTarget.style.backgroundColor === "rgb(190, 227, 248)") {
        e.currentTarget.style.backgroundColor = "#FFFFFF";
        e.currentTarget.style.borderWidth = "0px";
        e.currentTarget.style.borderColor = "#FFFFFF";
        setlastSelectedUserData(undefined);
      } else {
        e.currentTarget.style.backgroundColor = "#BEE3F8";
        e.currentTarget.style.borderWidth = "2px";
        e.currentTarget.style.borderColor = "#BEE3F8";

        var clonedData = { ...lastSelectedUserData };
        clonedData.user = user;
        clonedData.domElement = e.currentTarget;
        clonedData.index = index;
        setlastSelectedUserData(clonedData);
        if (lastSelectedUserData && lastSelectedUserData.domElement) {
          lastSelectedUserData.domElement.style.backgroundColor = "#FFFFFF";
          lastSelectedUserData.domElement.style.borderColor = "#FFFFFF";
          lastSelectedUserData.domElement.style.borderWidth = "0px";
        }
      }
    }
  }

  function handleResponse(json) {
    if (json) {
      var clonedData = [...users];
      if (
        lastSelectedUserData &&
        lastSelectedUserData.user &&
        operation !== "add"
      ) {
        clonedData[lastSelectedUserData.index] = json;
        setlastSelectedUserData({...lastSelectedUserData, user:json});
      } else {
        clonedData.push(json);
      }
      setUsers(clonedData);
    }
  }

  function handleUpdate() {
    setOperation("update");
    if (lastSelectedUserData) {
      var clonedData = { ...lastSelectedUserData };
      clonedData.operation = "update";
      setlastSelectedUserData(clonedData);
    }
    setIsOpen(true);
  }

  function handleDelete() {}

  function handleAdd() {
    setIsOpen(true);
    setOperation("add");
  }

  return (
    <>
    <Box height="50px">
    {
      users.length > 0 &&
      <CommonButtonGroup
        isShowAdd="true"
        isShowUpdate="true"
        isShowDelete="false"
        deleteFunction={handleDelete}
        addFunction={handleAdd}
        updateFunction={handleUpdate}
        selectedObject={lastSelectedUserData}
      ></CommonButtonGroup>
    }
    </Box>
    <Box height="750px" overflow="auto">
      <Flex  direction="row" flexWrap="wrap">
        {users.map((user, index) => {
          return (
            <Card
              mr={2}
              mt={2}
              onClick={(e) => {
                handleCardSelection(e, user, index);
              }}
              borderColor="gray.200"
              borderWidth={1}
              _hover={{ bg: "blue.100", cursor: "pointer" }}
              width="250px"
            >
              <Badge variant='solid' colorScheme={user.status === "Inactive"
                      ? user.status === "Temp"
                        ? "blue"
                        : "orange"
                      : "green"}><Center><Text fontWeight='bold' fontSize="12px">{user.status}</Text></Center></Badge>
              <CardBody>
                <Stack spacing="1">
                  <Heading size="sm">{user.firmName}</Heading>
                  <Text color="blue.700" fontSize="sm">
                    Address : {user.firmAddress}
                  </Text>
                  <Text color="blue.700" fontSize="sm">
                    GST Number : {user.gstNumber}
                  </Text>
                  <Text color="blue.700" fontSize="sm">
                    Gold Limit : {user.goldLimit}
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          );
        })}
      </Flex>
      <ModalDialog
        size="lg"
        isOpen={isOpen}
        closePopup={closePopup}
        responseHandlerFn={handleResponse}
        caption={lastSelectedUserData && operation !== "add" ? "Update" : "Add"}
      >
        {lastSelectedUserData && operation !== "add" ? (
          <EditUserForm {...props} selectedUser={lastSelectedUserData.user}></EditUserForm>
        ) : (
          <EditUserForm {...props}></EditUserForm>
        )}
      </ModalDialog>
      </Box>
      
    </>
  );
}

export default ShowAllUsers;
