import React, { useEffect, useRef, useState } from "react";

import { Box, HStack, Input, useToast, Image, Spacer, Button, Text, List } from "@chakra-ui/react";
import ShowAllProducts from "../pages/ShowAllProducts";
import httpService from "../../services/httpService";
import CommonButtonGroupNew from "../CommonButtonGroupNew";
import AddEditProductForm from "../Forms/AddEditProductForm";
import { RxDownload } from "react-icons/rx";
import { number } from "yup";

function SearchProductsDataProvider(props) {
  const [products, setProducts] = useState([]);
  const [showExport, setShowExport] = useState(true)
  const [lastSelectedProductData, setLastSelectedProductData] =
    useState(undefined);
  const toast = useToast();
  useEffect(() => {
    httpService.get("products/search/*", setProducts);
  }, []);

  function handleSelect(product) {
    setLastSelectedProductData(product);
  }

  function handleChange(value) {
    var code = value;
    if (code == "") {
      setShowExport(true);
      code = "*";
    }
    else {
      setShowExport(false);
    }
    httpService.get("products/search/" + code, setProducts);
  }

  function handleResponse(json) {
    if (json) {
      var clonedData = [...products];
      if (lastSelectedProductData && lastSelectedProductData.product) {
        clonedData[lastSelectedProductData.index] = json;
      } else {
        clonedData.push(json);
      }
      setProducts(clonedData);
    }
  }

  function handleDelete() {
    if (lastSelectedProductData) {
      httpService
        .post(
          "products/admin/deleteProduct/" + lastSelectedProductData.product.id
        )
        .then(() => {
          var clonedData = [...products];
          clonedData.splice(lastSelectedProductData.index, 1);
          toast({
            title: "Product deleted",
            status: "success",
            isClosable: true,
          });
          setProducts(clonedData);
        })
        .catch(() => {
          toast({
            title: "Product delete failed",
            status: "error",
            isClosable: true,
          });
        });
    }
  }

  function handleExport() {
    var iCount = products.length
    if(iCount > 0) {
      console.log(products)
      var exportJSON = []
      for (let index = 0; index < iCount; index++) {
        let objProduct = {}
        const product = products[index];
        objProduct["AVAILABLE_QUANTITY"] = product.availableQuantity
        objProduct["CODE"] = product.code
        let prodGrossWt = product.grossWeight.toFixed(3)
        objProduct["GROSS_WEIGHT"] = prodGrossWt
        objProduct["HEIGHT"] = product.height.toFixed(3)
        objProduct["WIDTH"] = product.width.toFixed(3)
        objProduct["OTHER_COST"] = product.otherCost
        objProduct["PURITY"] = product.purity
        objProduct["WASTAGE"] = product.wastage
        objProduct["TITLE"] = product.title
        let prodNetWt = product.weight.toFixed(3)
        objProduct["NET_WEIGHT"] = prodNetWt
        objProduct["LESS_WEIGHT"] = (prodGrossWt - prodNetWt).toFixed(3)

        exportJSON.push(objProduct)
      }

      if(exportJSON.length > 0) {
        var XLSX = require("xlsx");
        var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(exportJSON)
        XLSX.utils.book_append_sheet(wb, ws, "Products")

        XLSX.writeFile(wb, "ANK_PRODUCTS.xlsx")
        
      }

    }
    else {
      alert("No products to export Excel")
    }
  }

  return (
    <>
      <Box h="5vh">
        <HStack spacing={3}>
          <Box width="50%">
            <Input
              type="number"
              onChange={(e) => handleChange(e.currentTarget.value)}
              placeholder="Enter Procuct Code to Search"
              size="md"
            />
          </Box>
          <CommonButtonGroupNew
            isShowAdd="false"
            isShowUpdate="true"
            isShowDelete="true"
            deleteFunction={handleDelete}
            responseHandlerFn={handleResponse}
            selectedObject={lastSelectedProductData?.product || undefined}
          >
            {lastSelectedProductData !== undefined ? (
              <AddEditProductForm {...props}
                selectedProduct={lastSelectedProductData.product}
              ></AddEditProductForm>
            ) : (
              <AddEditProductForm {...props}></AddEditProductForm>
            )}
          </CommonButtonGroupNew>
          { showExport && 
              <Button 
                onClick={handleExport}
                position={'absolute'}
                right={5}
                colorScheme={"green"}
                variant="solid">
                <RxDownload  color={"white"}/>
                <Text paddingLeft={1}>
                  Export
                </Text>
              </Button>
        }  
        </HStack>
      </Box>
      <Box marginTop="2">
        <ShowAllProducts
          {...props}
          lastSelectedProductData={lastSelectedProductData}
          handleSelect={handleSelect}
          products={products}
        ></ShowAllProducts>
      </Box>
    </>

  );
}

export default SearchProductsDataProvider;
