/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */
var exports = {};
var isLoggedIn = false;
var basePath = "https://62.72.57.189:8080/";

export let get = function (url, stateCallbackFn) {
  return new Promise(function (resolve, reject) {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer" + " " + sessionStorage.getItem("ankToken"),
        },
      };
      fetch(basePath + url, requestOptions)
        .then((response) => {
      if (!response.ok) {
            return Promise.reject(response);
          }
          return response.json();
      })
        .then((json) => {
          if (stateCallbackFn !== null && stateCallbackFn !== undefined) {
            stateCallbackFn(json);
            resolve(json);
          }
        })
        .catch((error) => {
          if (error && error.json) {
            error.json().then((jsonError) => {
            if(jsonError.message && jsonError.message.startsWith("Full authentication is required"))
            {
              isLoggedIn = false;
              sessionStorage.removeItem("ankToken");
              reject("Session Expired. Login again to continue");
            }
            else
            {
              reject(jsonError.message);
            }
            });
          } else {
            reject("Network Error");
          }
        });
    } catch (err) {
      console.log(err.message);
    }
  });
}

export let post = function (
  url,
  inputCallBackFnOrBody,
  stateCallbackFn,
  contentType
) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer" + " " + sessionStorage.getItem("ankToken"),
      },
    };
    if (inputCallBackFnOrBody) {
      if (contentType) {
        requestOptions.body = inputCallBackFnOrBody();
      } else {
        if(typeof inputCallBackFnOrBody === 'function')
        {
        requestOptions.body = JSON.stringify(inputCallBackFnOrBody());
        }
        else{
          requestOptions.body = JSON.stringify(inputCallBackFnOrBody);
        }
      }
    }
    if (contentType===undefined)
    {
      requestOptions.headers["Content-Type"] = "application/json";
    }

    fetch(basePath + url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json();
      })
      .then((json) => {
        resolve(json);
        if (stateCallbackFn) {
          stateCallbackFn(json);
        }
      })
      .catch((error) => {
        if (error && error.json) {
          error.json().then((jsonError) => {
          if(jsonError.message && jsonError.message.startsWith("Full authentication is required"))
          {
            isLoggedIn = false;
            sessionStorage.removeItem("ankToken");
            reject("Session Expired. Login again to continue");
          }
          else
          {
            reject(jsonError.message);
          }
          });
        } else {
          reject("Network Error");
        }
      });
  });
};

export let login = function (username, password) {
  return new Promise(function (resolve, reject) {
    var input = {
      username: username,
      password: password,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    };

    fetch(basePath + "api/auth/signin", requestOptions)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json();
      })
      .then((json) => {
        if(json.roles && json.roles.indexOf("ROLE_ADMIN") === -1 || json.roles=== undefined || json.roles === null)
        {
          reject("Unauthorized user");
        }
        isLoggedIn = true;
        var jwtToken = json.accessToken;
        sessionStorage.setItem("ankToken", jwtToken);
        resolve("Login Successful");
      })
      .catch((error) => {
        if (error && error.json) {
          error.json().then((jsonError) => {
            reject(jsonError.message);
          });
        } else {
          reject("Network Error");
        }
      });
  });
};

export let logout = function () {
  return new Promise(function (resolve, reject) {
  
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer" + " " + sessionStorage.getItem("ankToken"),
      }
    };
    fetch(basePath + 'api/auth/logout', requestOptions)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    })
      .then(json => {
        isLoggedIn = false;
        sessionStorage.removeItem("ankToken")
        resolve(json);
        window.history.pushState(null, "", window.location.href);
        window.addEventListener('popstate', function (event)
        {
          window.history.go(1);
        });
      })
      .catch(error => {
        isLoggedIn = false;
        sessionStorage.removeItem("ankToken");
        reject(error.message);
        window.history.pushState(null, "", window.location.href);
        window.addEventListener('popstate', function (event)
        {
          window.history.go(1);
        });
      })
  });
};

export default exports = {
  get,
  post,
  login,
  logout,
};
