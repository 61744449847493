import { ChakraProvider } from "@chakra-ui/react";

import {
  UserDetailsProvider,

} from "./Components/AppContext/AppContext";
import NavigationContext from "./Components/SIdebar/NavigationContext";

function App() {
  return (
    
      <ChakraProvider>
        <UserDetailsProvider>
        <NavigationContext></NavigationContext>
        </UserDetailsProvider>
      </ChakraProvider>
  );
}

export default App;
