
import { Route, Routes } from "react-router-dom";
import { UserDetailsProvider, useUserContext } from "../AppContext/AppContext";
import ProductDataProvider from "../DataProvider/ProductDataProvider";
import Login from "../Forms/Login";
import Home from "../Home/home";
import NathTypeAndPurity from "../pages/NathTypeAndPurity";
import ShowAllCategories from "../pages/ShowAllCategories";
import ShowAllOrders from "../pages/ShowAllOrders";
import ShowAllProducts from "../pages/ShowAllProducts";
import ShowAllUsers from "../pages/ShowAllUsers";
import ShowOrderDetails from "../pages/ShowOrderDetails";
import SidebarLayout from "./SidebarLayout";
import SidebarWithHeader from "./SidebarWithHeader";
import SearchProductsDataProvider from "../DataProvider/SearchProductsDataProvider";



function NavigationContext(props) {
  const { isLoggedIn } = useUserContext();

  return (

    <UserDetailsProvider>
      <Routes>
        <Route index path="/" element={<Login {...props}></Login>}></Route>
        <Route index path="/login" element={<Login {...props}></Login>}></Route>
        <Route element={<SidebarLayout {...props}></SidebarLayout>}>
          <Route index path="/home" element={<Home {...props}></Home>}></Route>
          <Route
            path="/categories"
            element={<ShowAllCategories {...props}></ShowAllCategories>}
          ></Route>
          <Route
            path="/sidebar"
            element={<SidebarWithHeader {...props}></SidebarWithHeader>}
          ></Route>
          <Route
            path="/nathType"
            element={<NathTypeAndPurity {...props}></NathTypeAndPurity>}
          ></Route>
          <Route
            path="/products"
            element={<ProductDataProvider {...props}>
            </ProductDataProvider>}
          ></Route>
          <Route
            path="/Users"
            element={<ShowAllUsers {...props}></ShowAllUsers>}
          ></Route>
          <Route
            path="/orders"
            element={<ShowAllOrders {...props}></ShowAllOrders>}
          ></Route>
          <Route
            path="/orderDetails"
            element={<ShowOrderDetails {...props}></ShowOrderDetails>}
          ></Route>
          <Route
            path="/search"
            element={<SearchProductsDataProvider {...props}></SearchProductsDataProvider>}
          ></Route>
        </Route>
      </Routes>
    </UserDetailsProvider>
  );
}

export default NavigationContext;
