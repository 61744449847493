import {
  FiHome,
  FiTrendingUp,
  FiStar,
} from "react-icons/fi";

var exports = {};
export let getCategoriesPageMenues = function () {
  return [
    { name: "Home", icon: FiHome, pageLink: "/home" },
    { name: "Categories", icon: FiStar, pageLink: "/categories" },
    { name: "Nath Type", icon: FiTrendingUp, pageLink: "/nathType" },
  ];
};

export let getMenuItems = function (key) {
  if (key) {
    if (key === "/categories" || key === "/nathType") {
      return getCategoriesPageMenues();
    }
  }
  return [{ name: "Home", icon: FiHome, pageLink: "/home" }];
};

export default exports = {
  getMenuItems,
};
