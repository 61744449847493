import {
  Heading,
  Flex,
  Card,
  CardBody,
  Stack,
  CardFooter,
  Text,
  IconButton,
  chakra,
  useToast,
  Select,
  Box,
  HStack,
  Button,
  Center,
  Highlight,
  Badge
} from "@chakra-ui/react";

import { useEffect, useRef, useState } from "react";
import httpService from "../../services/httpService";

import { RxOpenInNewWindow } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

function ShowAllOrders() {
  const startDate = useRef(undefined);
  const endDate = useRef(undefined);
  const firmName = useRef(undefined);
  const status = useRef(undefined);
  const [allUserOrders, setAllUserOrders] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [lastSelectedUserData, setlastSelectedUserData] = useState(undefined);
  const toast = useToast();
  const [allOrdersToShow, setAllOrdersToShow] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [firmNamesList, setFirmNamesList] = useState([]);
  const navigate = useNavigate();
  const CFaUserAlt = chakra(RxOpenInNewWindow);

  function handleError(msg) {
    if (msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    } else {
      showToast(msg, "error");
    }
  }

  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }

  useEffect(() => {
    httpService.post(
      "order/admin/getAllUsersOrders",
      getAllUSersDataInput,
      setAllUserOrders
    ).catch((msg) => {
      handleError(msg);
    });
  }, []);

  function getAllUSersDataInput() {
    if (pageInfo.selectedPage) {
      return {
        pageSize: 15,
        pageNo: pageInfo.selectedPage,
      };
    }
    return {
      pageSize: 15,
      pageNo: 0,
    };
  }

  function getPageData(pageNumber) {
    if (pageInfo.selectedPage !== pageNumber) {
      httpService.post(
        "order/admin/getAllUsersOrders",
        {
          pageSize: 15,
          pageNo: pageNumber,
        },
        setAllUserOrders
      );
    }
  }

  function handlePaginationButtonClick(e, pageNumber) {
    if (e.currentTarget.style.backgroundColor !== "rgb(190, 227, 248)") {
      e.currentTarget.style.backgroundColor = "#BEE3F8";
      if (pageInfo.lastSelectedElement) {
        pageInfo.lastSelectedElement.style.backgroundColor = "unset";
      }
    }
    setPageInfo({ ...pageInfo, lastSelectedElement: e.currentTarget });
    getPageData(pageNumber);
  }

  useEffect(() => {
    var allOrders = [];
    var uniqueStatuses = [];
    var allorders = allUserOrders.allOrders;
    if (allorders && allorders.length > 0) {
      for (var i = 0; i < allorders.length; i++) {
        var orders = allorders[i].orders;
        for (var j = 0; j < orders.length; j++) {
          var order = orders[j];
          order.firmName = allorders[i].user.firmName;
          order.firmAddress = allorders[i].user.firmAddress;
          allOrders.push(order);

          if (!uniqueStatuses.includes(order.orderStatus)) {
            uniqueStatuses.push(order.orderStatus);
          }
          var color = "";
          if (order.orderStatus === "In Process") {
            color = "orange";
          } else if (order.orderStatus === "Rejected") {
            color = "red";
          } else if (order.orderStatus === "Approved") {
            color = "blue";
          } else if (order.orderStatus === "Completed") {
            color = "green";
          }
          order.bgColor = color;
        }
      }

      setPageInfo({
        ...pageInfo,
        hasNextPage: allUserOrders.hasNextPage,
        totalPages: allUserOrders.totalPages,
        totalFound: allUserOrders.totalFound,
        selectedPage: allUserOrders.currentPage,
      });
      setFirmNamesList(allUserOrders.firmNames);
      setAllOrdersToShow(allOrders);
      setStatusList(uniqueStatuses);
    } else {
      setAllOrdersToShow([]);
    }
  }, [allUserOrders]);

  function handleCardSelection(e, user, index) {
    if (e.currentTarget) {
      if (e.currentTarget.style.backgroundColor === "rgb(190, 227, 248)") {
        e.currentTarget.style.backgroundColor = "#FFFFFF";
        e.currentTarget.style.borderWidth = "0px";
        e.currentTarget.style.borderColor = "#FFFFFF";
        setlastSelectedUserData(undefined);
      } else {
        e.currentTarget.style.backgroundColor = "#BEE3F8";
        e.currentTarget.style.borderWidth = "2px";
        e.currentTarget.style.borderColor = "#BEE3F8";

        var clonedData = { ...lastSelectedUserData };
        clonedData.user = user;
        clonedData.domElement = e.currentTarget;
        clonedData.index = index;
        setlastSelectedUserData(clonedData);
        if (lastSelectedUserData && lastSelectedUserData.domElement) {
          lastSelectedUserData.domElement.style.backgroundColor = "#FFFFFF";
          lastSelectedUserData.domElement.style.borderColor = "#FFFFFF";
          lastSelectedUserData.domElement.style.borderWidth = "0px";
        }
      }
    }
  }

  function filterOrders(value, type) {
    
    if (type === "status") {
      var orders = [...allOrdersToShow];
      for (var i = 0; i < orders.length; i++) {
        var order = orders[i];
        if (value !== "" && order.orderStatus !== value) {
          order.hidden = true;
        } else {
          order.hidden = false;
        }
      }
      setAllOrdersToShow(orders);
    } else {
      httpService.post(
        "order/admin/getAllUsersOrders",
        {
          pageSize: 15,
          pageNo: 0,
          firmName: value,
        },
        setAllUserOrders
      )
      .catch((msg) => {
        handleError(msg);
      });
    }
  }

  function openOrderDetailsPage(orderToShow) {
    navigate("/orderDetails", { state: { order: orderToShow } });
  }

  function getOrdersByDateInput() {
    var input = {};
    var startDateSelected = startDate.current.value;
    var endDateSelected = endDate.current.value;
    if (startDateSelected === "" || endDateSelected === "") {
      input = {
        pageSize: 15,
        pageNo: 0,
      };
    } else {
      input = {
        startDate: startDateSelected,
        endDate: endDateSelected,
        pageSize: 15,
        pageNo: 0,
      };
    }
    if (firmName.current.value !== "") {
      input.firmName = firmName.current.value;
    }
    if (status.current.value !== "") {
      input.status = status.current.value;
    }
    return input;
  }

  function getOrdersByDate() {
    httpService.post(
      "order/admin/getAllUsersOrders",
      getOrdersByDateInput,
      setAllUserOrders
    ).catch((msg) => {
      handleError(msg);
    });
  }

  return (
    <>
      {
      <Box h="5vh" pointerEvents={allUserOrders.allOrders ? "auto" : "none"}>
        <HStack spacing={10}>
          <Box width="30%">
            <Select
              ref={status}
              onChange={(e) => filterOrders(e.currentTarget.value, "status")}
              placeholder="Select status to filter"
              maxH="30"
              borderColor="black"
            >
              {statusList.map((status) => {
                return <option value={status}>{status}</option>;
              })}
            </Select>
          </Box>
          <Box width="30%">
            <Select
              ref={firmName}
              onChange={(e) => filterOrders(e.currentTarget.value, "firmName")}
              placeholder="Select Firm Name to filter"
              maxH="30"
              borderColor="black"
            >
              {firmNamesList.map((firmName) => {
                return (
                  <option value={firmName.username}>
                    {firmName.firm_Name}
                  </option>
                );
              })}
            </Select>
          </Box>
          <Box>
            <Text>Start Date</Text>
            <input
              ref={startDate}
              type="date"
              id="startDate"
              name="startDate"
            ></input>
          </Box>
          <Box>
            <Text>End Date</Text>
            <input
              ref={endDate}
              type="date"
              id="startDate"
              name="startDate"
            ></input>
          </Box>
          <Button onClick={getOrdersByDate} colorScheme="blue">
            Apply
          </Button>
        </HStack>
      </Box>
      }
      <Flex mt={5} maxH="80vh" direction="row" flexWrap="wrap" overflow="auto">
        {allOrdersToShow.map((userOrder, index) => {
          return (
            (userOrder.hidden === undefined || userOrder.hidden === false) && (
              <Card
                borderColor="gray.300"
                borderWidth={1}
                mr={2}
                mt={1}
                onClick={(e) => {
                  handleCardSelection(e, userOrder, index);
                }}
                _hover={{ bg: "blue.100", cursor: "pointer" }}
                h="150px"
                w="250px"
              >
                <Badge variant='solid' colorScheme={userOrder.bgColor}><Center><Text fontWeight='bold' fontSize="12px">{userOrder.orderStatus}</Text></Center></Badge>
                <CardBody>
                
                  <Stack spacing="1">
                    <Heading size="sm">{userOrder.firmName}</Heading>
                    <Text color="blue.700" fontSize="sm">
                      Date : {userOrder.dateCreated.split("T")[0]}
                    </Text>
                  </Stack>
                </CardBody>
                <CardFooter p={0} justify="flex-end">
                  <IconButton
                    onClick={() => {
                      openOrderDetailsPage(userOrder);
                    }}
                    variant="ghost"
                    colorScheme="gray"
                    aria-label="See menu"
                    icon={<CFaUserAlt />}
                  />
                </CardFooter>
              </Card>
            )
          );
        })}
      </Flex>

      <Center>
        <div
          style={{ bottom: "5px", position: "fixed", justifyItems: "center" }}
        >
        {pageInfo.totalPages &&  <HStack>
            <Button
              isDisabled={pageInfo.selectedPage === undefined || pageInfo.selectedPage === 0}
              _hover={{ bg: "blue.100", cursor: "pointer" }}
              onClick={() => getPageData(pageInfo.selectedPage - 1)}
              borderWidth={2}
              borderRadius="full"
              borderColor="blue.300"
              height={8}
            >
              <Center> Prev </Center>
            </Button>
            {pageInfo.totalPages > 10
              ? [...Array(10).keys()].map((number) => {
                  return (
                    <Button
                      _hover={{ bg: "blue.100", cursor: "pointer" }}
                      onClick={(e) => {
                        handlePaginationButtonClick(e, number);
                      }}
                      borderWidth={2}
                      borderRadius="full"
                      borderColor="blue.300"
                    >
                      <Center>{number + 1}</Center>
                    </Button>
                  );
                })
              : [...Array(pageInfo.totalPages).keys()].map((number) => {
                  return (
                    <Button
                      _hover={{ bg: "blue.100", cursor: "pointer" }}
                      onClick={(e) => {
                        handlePaginationButtonClick(e, number);
                      }}
                      borderWidth={2}
                      borderRadius="full"
                      borderColor="blue.300"
                      width={8}
                      height={8}
                    >
                      <Center>{number + 1}</Center>
                    </Button>
                  );
                })}

            <Button
              isDisabled={ pageInfo.hasNextPage === undefined || pageInfo.hasNextPage === false  }
              _hover={{ bg: "blue.100", cursor: "pointer" }}
              onClick={() => getPageData(pageInfo.selectedPage + 1)}
              borderWidth={2}
              borderRadius="full"
              borderColor="blue.300"
              as="button"
              height={8}
            >
              <Center>Next</Center>
            </Button>
          </HStack>
        }
        </div>
      </Center>
    </>
  );
}

export default ShowAllOrders;
