import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Icon,
  useToast,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import MenuItems from "../../services/MenuItems";
import { useLocation } from "react-router-dom";
import httpService, { logout } from "../../services/httpService";
import Loading from "./Loading";
import React from "react";
const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={"#"}
  >
    {children}
  </Link>
);



const NavItem = ({ icon, pageLink, children, ...rest }) => {
  const navigate = useNavigate();
  function loadPage(pageLink)
{
  navigate(pageLink);
}
  return (
    <Link
      onClick={loadPage}
      href={pageLink}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

export default function Simple({ showIsLoading,children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  function handleLogout() {
    httpService
      .logout()
      .then(() => {
        navigate("/login");
      })
      .catch(() => {
        toast({
          title: "Logout Failed",
          status: "error",
          isClosable: true,
        });
      });
  }


  return (
    <>
      <Box  p='2' bg={useColorModeValue("gray.100", "gray.300")}>
        <Flex justifyContent={"space-between"} pr={2}>
          {/* <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          /> */}
          <HStack >
            <Box>
              <Image _hover={{ cursor: "pointer" }} onClick={() => navigate('/home')} src="/logo.png" height="70" alt="image" />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {MenuItems.getMenuItems(location.pathname).map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  pageLink={link.pageLink}
                >
                  {link.name}
                </NavItem>
              ))}
            </HStack>
          </HStack>
          <Flex>
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar size={"sm"} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
      <Box heigth="90vh"  pt={4} >
      
      {children}
      </Box>
      <Loading show={showIsLoading}></Loading>
    </>
  );
}
