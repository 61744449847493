import React, { useEffect, useRef, useState } from "react";
import httpService from "../../services/httpService";
import { useLocation } from "react-router-dom";
import CommonButtonGroupNew from "../CommonButtonGroupNew";
import { HStack, Text, useToast } from "@chakra-ui/react";
import AddEditProductForm from "../Forms/AddEditProductForm";
import ShowAllProducts from "../pages/ShowAllProducts";
import DeleteConfirmationDialog from "../Forms/AlertDialog";
import { useNavigate } from "react-router-dom";

function ProductDataProvider(props) {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [lastSelectedProductData, setLastSelectedProductData] = useState(undefined);
  const toast = useToast();
  const [operation, setOperation] = useState("");
  const [isShowConfirmationDialog, setIsShowConfirmationDialog] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (!location?.state?.selectedCategoryId) {
      httpService.logout();
      navigate("/login");
    }
    else {
      httpService.get(
        "products/getAllProducts/" + location.state.selectedCategoryId,
        setProducts
      )
    }
  }, []);

  function handleAdd() {
    setOperation('add');
  }

  function closePopup() {
    setIsShowConfirmationDialog(false);
  }

  function handleSelect(product) {
    setLastSelectedProductData(product);
  }

  function handleResponse(json) {
    if (json) {
      var clonedData = [...products];
      if (lastSelectedProductData && lastSelectedProductData.product) {
        clonedData[lastSelectedProductData.index] = json;
      } else {
        clonedData.push(json);
      }
      setProducts(clonedData);
    }
    setOperation(undefined);
  }

  function handleDelete() {
    setOperation(undefined);
    if (!isShowConfirmationDialog) {
      setIsShowConfirmationDialog(true);
      return;
    }
    else {
      setIsShowConfirmationDialog(false);
      if (lastSelectedProductData) {
        httpService
          .post("products/admin/deleteProduct/" + lastSelectedProductData.product.id)
          .then(() => {
            var clonedData = [...products];
            clonedData.splice(lastSelectedProductData.index, 1);
            toast({
              title: "Product deleted",
              status: "success",
              isClosable: true,
            });
            setLastSelectedProductData(undefined);
            setProducts(clonedData);
          })
          .catch((error) => {
            toast({
              title: "Product delete failed",
              status: "error",
              isClosable: true,
            });
          });
      }
    }


  }

  return (
    <>
      <HStack spacing={20}>
        <Text fontSize='2xl' as="b" m="0" p="0"> {location?.state?.name}</Text>
        <CommonButtonGroupNew
          isShowAdd="true"
          isShowUpdate="true"
          isShowDelete="true"
          addFunction={handleAdd}
          deleteFunction={handleDelete}
          responseHandlerFn={handleResponse}
          selectedObject={lastSelectedProductData?.product || undefined}
        >
          {
            lastSelectedProductData !== undefined && operation !== 'add' ?
              <AddEditProductForm  {...props} selectedCategoryId={location?.state?.selectedCategoryId} selectedProduct={lastSelectedProductData?.product}></AddEditProductForm> :
              <AddEditProductForm {...props} selectedCategoryId={location?.state?.selectedCategoryId} purity={location?.state?.purity}></AddEditProductForm>
          }

        </CommonButtonGroupNew>
      </HStack>

      <ShowAllProducts {...props} lastSelectedProductData={lastSelectedProductData} handleSelect={handleSelect} products={products} />
      <DeleteConfirmationDialog isOpen={isShowConfirmationDialog} handleDelete={handleDelete} closePopup={closePopup}/>
    </>
  );
}

export default ProductDataProvider;
